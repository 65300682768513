<template>
  <b-modal id="worktime-modal" size="xl"     @hidden="$emit('resetModal')"
 hide-footer>
    <b-tabs>
      <b-tab v-for="(tab, i) in tabs" :title="tab" :key="i">
        <FormulateForm
          name="workstaionForm"
          v-model="values"
          @submit="submitHandler"
        >
          <FormulateInput
            type="time"
            label="Start Time"
            v-model="start_time[i]"
            :id="'start_' + i"
          />
          <FormulateInput type="time" label="End Time" v-model="end_time[i]"
          :id="'end_' + i" />
          <FormulateInput type="submit" class="mt-3 float-right">
            <span v-if="sending"> Updating..</span><span v-else>Set Time</span>
          </FormulateInput>
          <FormulateInput type="button" @click="submitHandlerAll(i)" class="mt-3 float-left">
            <span v-if="sending"> Updating..</span><span v-else>Set Time to All</span>
          </FormulateInput>
        </FormulateForm>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { workstations } from "@/config/api/workstations";
export default {
  props: ["selectedDriver", "selectedWorkStation"],
  components: {},
  mounted() {
          this.$bvModal.show("worktime-modal");

    const api = workstations.byId;
      api.id = this.selectedWorkStation._id;
      this.generateAPI(api).then((res) => {
        console.log(res)
        for (const [key, value] of Object.entries(res.data.workstation.working_time)) {
            this.start_time[this.daysOfWeek[key]] = value.start_time
            this.end_time[this.daysOfWeek[key]] = value.end_time
            document.getElementById("start_" + this.daysOfWeek[key]).value = this.start_time[this.daysOfWeek[key]]
            document.getElementById("end_" + this.daysOfWeek[key]).value = this.end_time[this.daysOfWeek[key]]}
        // res.data.workstation.working_time.forEach((item) => {

        //   this.start_time[this.daysOfWeek[Object.keys(item)[0]]] = item[Object.keys(item)[0]].start_time
        //   this.end_time[this.daysOfWeek[Object.keys(item)[0]]] = item[Object.keys(item)[0]].end_time
        //    document.getElementById("start_" + this.daysOfWeek[Object.keys(item)[0]]).value = this.start_time[this.daysOfWeek[Object.keys(item)[0]]]
        //    document.getElementById("end_" + this.daysOfWeek[Object.keys(item)[0]]).value = this.end_time[this.daysOfWeek[Object.keys(item)[0]]]

        // })
      })
  },
  data() {
    return {
      start_time: [],
      end_time: [],
      values: {},
      sending: false,
      marker: null,
      daysOfWeek: {
        "Monday": 0,
        "Tuesday": 1,
        "Wednesday": 2,
        "Thursday": 3,
        "Friday": 4,
        "Saturday": 5,
        "Sunday": 6,
        },
    };
  },
  methods: {
    async submitHandler() {
      const api = workstations.update;

      api.id = this.selectedWorkStation._id;
      let data = {
        working_time: {},
      };
      let loopWeek = false;
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.start_time[i]) {
          let start_num = this.start_time[i].split(":");
          let end_num = this.end_time[i].split(":");

          if (
            parseInt(start_num[0]) > parseInt(end_num[0]) &&
            parseInt(start_num[0]) > 12 &&
            parseInt(end_num[0]) < 12
          ) {
            this.end_time[i + 1 > 6 ? 0 : i + 1] = this.end_time[i];
            this.end_time[i] = "23:59";
            this.start_time[i + 1 > 6 ? 0 : i + 1] = "00:00";
            loopWeek = i + 1 > 6 ? true : false;
          }
          data.working_time[this.tabs[i]] = {
            start_time: this.start_time[i],
            end_time: this.end_time[i],
          };
          if (loopWeek) {
            data.working_time[this.tabs[i]] = {
              start_time: this.start_time[i],
              end_time: this.end_time[i],
            };
          }
        }
      }
      api.data = {
        ...data,
      };

      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("workstaionForm");
          this.$bvToast.toast("Workstation added successfully", {
            title: "Workstation",
            variant: "success",
            solid: true,
          });
          this.$emit("workstationAdded", true);
        })
        .catch(() => {
          this.$bvToast.toast("Workstation is not added", {
            title: "Workstation",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
      async submitHandlerAll(index) {
      const api = workstations.update;

      api.id = this.selectedWorkStation._id;
      let data = {
        working_time: [],
      };
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.start_time[index]) {

          data.working_time.push({
            [this.tabs[i]]: {
              start_time: this.start_time[index],
              end_time: this.end_time[index],
            },
          });
         
        }
      }
      api.data = {
        ...data,
      };

      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("workstaionForm");
          this.$bvToast.toast("Workstation added successfully", {
            title: "Workstation",
            variant: "success",
            solid: true,
          });
          this.$emit("workstationAdded", true);
        })
        .catch(() => {
          this.$bvToast.toast("Workstation is not added", {
            title: "Workstation",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    update(val) {
      console.log(val);
    },
    locationChange(location) {
      console.log("New location", location);
      this.marker = location;
    },
  },
  watch: {},
  computed: {
    tabs() {

      return Object.keys(this.daysOfWeek).map(function(key) {
  return key
});
}
  }
};
</script>
